/* EXTRAS */ 
//scsssss
@import 'extras/_reset.scss';

/* BASE */
@import 'base/_variables.scss';
@import 'base/_placeholders.scss';
@import 'base/_mixins.scss';

/* ESTRUCTURA */
@import 'estructura/_layout.scss';

/* MODULOS */
@import 'modulos/_botones.scss';
@import 'modulos/_formularios.scss';
@import 'modulos/_footer.scss';
@import 'modulos/_header.scss';
@import 'modulos/_migas.scss';
@import 'modulos/_slider.scss';

/* SECCIONES */
@import 'secciones/_contacto.scss';
@import 'secciones/_home.scss';
@import 'secciones/_propiedades.scss';
@import 'secciones/_overlay.scss';
@import 'secciones/_ficha.scss';
@import 'secciones/_consulta.scss';
@import 'secciones/_admin.scss';
@import 'secciones/_agradecimiento.scss';

@media print{
	@import 'secciones/_print.scss';
}