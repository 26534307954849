/* HOME */

section.home{
	margin-top: 10px;
	.wrapper{
		width: 100%;
		overflow: hidden;
		background: url('../img/bg.jpg') no-repeat #fff;
		background-size: cover;
		@include respondTo(tablet){
			//overflow-y: scroll;
		}
		.contenedor-de-slides{
			position: relative;
			@include transition(0.8s);
			.slide,
			video{
				background: $azul-logo;
				color: $blanco;
				letter-spacing: 0.68px;
				font-family: $font;
				float: left;
				position: relative;
				background-size: cover;
				background-position: center center;
				.overlay{
					top: 0;
					background: rgba(0,0,0,0.3);
					position: absolute;
					width: 100%;
					@include respondTo(tablet){
						//background: rgba(0,0,0,0.6);
					}
					.row{
						@include respondTo(tablet){
							display: none;
						}
						p{
							@extend %p;
							font-size: 18px;
							color: $blanco;
							letter-spacing: 0.68px;
						}
					}
				}
			}
			video{
				//width: 100% !important;
				//height: auto !important;
				object-fit: cover;
  			}
		}

		$multiplicadorDesktop: 0.8;

		.filtros{
			$filtrosWidth: 850px;
			position: absolute;
			color: $blanco;
			width: $filtrosWidth;
			@include respondTo(tablet){
				display: none;
			}
			@include respondTo(desktop){
				width: $filtrosWidth * $multiplicadorDesktop;
			}
			.primera{
				position: absolute;
				z-index: 3;
				@include respondTo(tablet){
					display: none;
				}
			}
			fieldset{
				float: left;
				&:first-child{
					margin-left: 35px;
				}
				label{
					display: block;
					font-family: $font;
					font-size: 16px;
					color: rgba(255,255,255,0.8);
					letter-spacing: 0.6px;
					font-weight: 300;
					margin-bottom: 7px;
				}
				select{
					width: 100%;
					@include border-radius(0px);
					border: 0;
					height: 70px;
					font-family: $font;
					font-size: 18px;
					color: #3D3D3D;
					letter-spacing: 0.68px;	
					padding-left: 10px;	
					background: url('../img/flecha_negra_abajo.png') no-repeat #fff;
					background-position: 95% 58%;
					@include respondTo(desktop){
						font-size: 14px;
					}
					@include respondTo(tablet){
						background-position: fixed;
					}
					option{
						border: 0;
						padding: 5px 10px 5px 10px;
						text-align: center;
						&:hover{
							background: $azul-logo !important;
							color: $blanco;
						}
					}
					// RESETEA ESTILOS DE SELECT
					outline: none;	
					appearance:none;
					-moz-appearance:none; 
					-webkit-appearance:none; 					
						&:-moz-focusring {
					    color: transparent;
					    text-shadow: 0 0 0 #000;
					}	
					// HASTA ACÁ
				}
				.formHolder{
					width: 100%;
					@include border-radius(0px);
					border: 0;
					height: 70px;
					font-family: $font;
					font-size: 18px;
					color: #3D3D3D;
					letter-spacing: 0.68px;	
					padding-left: 10px;	
					background: $blanco;
					cursor: pointer;
					.scrollableList{
						height: 70px;
						@include scrollbars(10px, $azul-logo, $blanco);
						.selectedOption{
							height: 70px;
							padding-top: 25px;
							text-align: center;
							padding-right: 10px;
						}
						ul{
							width: calc(100% + 10px);
							margin-left: -10px;
							@include border-radius(0px);
							border: 0;
							font-family: $font;
							font-size: 18px;
							color: #3D3D3D;
							letter-spacing: 0.68px;	
							background: $blanco;
							@include box-shadow(5px, 5px, 5px, 2px, rgba(0,0,0,0.3));
							max-height: 210px;
							overflow-y: auto;
							@include respondTo(desktop){
								font-size: 14px;
							}
							@include respondTo(tablet){
								background-position: fixed;
							}
							li{
								background: $blanco;
								border: 0;
								padding: 12px 10px 12px 10px;
								text-align: center;
								cursor: pointer;
								@include transition(0.3s);
								&:hover{
									background: $azul-logo !important;
									color: $blanco;
									@include transition(0.3s);
								}
							}
						}
					}
				}
				.flecha{
					background: url('../img/flecha_negra_abajo.png') no-repeat transparent;
					background-position: center center;
					width: 20px;
					height: 10px;
					position: absolute;
					float: right;
					z-index: 1;
					margin-top: -40px;
					cursor: pointer;
					@include transition(0.4s);
				}
				.barra{
					width: 2px;
					height: 38px;
					background: rgba(0,0,0,0.1);
					position: absolute;
					z-index: 4;
				}
			}
			fieldset.barrio{
				$barrioWidth: 364px;
				width: $barrioWidth;
				z-index: 3;
				@include respondTo(desktop){
					width: $barrioWidth * $multiplicadorDesktop;
				}
				.barra{
					left: 280px;
					top: 40px;
					@include respondTo(desktop){
						left: 525px;
					}						
				}
				.flecha{
					margin-left: 317px;
					@include respondTo(desktop){
						margin-left: 257px;
					}
				}
			}
			fieldset.operacion{
				$operacionWidth: 170px;
				width: $operacionWidth;
				z-index: 3;
				@include respondTo(desktop){
					width: $operacionWidth * $multiplicadorDesktop;
				}
				.flecha{
					margin-left: 125px;
					@include respondTo(desktop){
						margin-left: 98px;
					}
				}
			}
			fieldset.tipo{
				$tipoWidth: 244px;
				width: $tipoWidth;
				z-index: 3;
				@include respondTo(desktop){
					width: $tipoWidth * $multiplicadorDesktop;
				}
				.barra{
					left: 644px;
					top: 40px;
					@include respondTo(desktop){
						left: 230px;
					}						
				}
				.flecha{
					margin-left: 200px;
					@include respondTo(desktop){
						margin-left: 155px;
					}
				}
			}
			.mas{
				$masMarginLeft: 845px;
				width: 40px;
				height: 40px;
				background: $blanco;
				@include border-radius(20px);
				margin-left: $masMarginLeft;
				margin-top: 40px;
				cursor: pointer;
				@include box-shadow(0, 2px, 10px, 0px, rgba(0,0,0,0.35));
				@include respondTo(desktop){
					margin-left: $masMarginLeft * $multiplicadorDesktop;
				}
				.barra{
					position: absolute;
					height: 2px;
					width: 20px;
					background: $gris-1;
					margin-top: 19px;
					margin-left: 10px;
					@include transition(0.7s);
				}
				.barra.ver{
					@include rotar(90);
				}
				&:hover .barra{
					background: $gris-oscuro;
					@include transition(0.5s);
				}
			}
			.sombra{
				$sombraWidth: 778px;
				width: $sombraWidth;
				z-index: 2;
				margin-top: 23px;
				margin-left: 35px;
				height: 70px;
				position: absolute;
				@include box-shadow(0, 2px, 10px, 0px, rgba(0,0,0,0.35));
				@include respondTo(tablet){
					display: none;
				}
				@include respondTo(desktop){
					width: $sombraWidth * $multiplicadorDesktop;
				}
			}
			#toggle{
				z-index: 1;
				margin-top: 23px;
				position: absolute;
				margin-left: 180px;
				width: 515px;
				@include respondTo(tablet){
					display: none;
				}
				@include respondTo(desktop){
					margin-left: 90px;
					width: auto;
				}
				.dormitorios{
					$dormitoriosWidth: 180px;
					width: $dormitoriosWidth;
					@include respondTo(desktop){
						width: $dormitoriosWidth * ($multiplicadorDesktop * 0.99);
					}
					select{
						padding-left: 30px;
						font-size: 14px;
						background-size: 20px 15px;
						background-position: 80% 55%;
						@include respondTo(desktop){
							padding-left: 10px;
						}
					}
					.formHolder{
						font-size: 14px;
						.selectedOption{
							padding-top: 28px;
							padding-right: 20px;
						}
						ul{
							//padding-left: 30px;
							font-size: 14px;
							max-height: 155px;
							@include respondTo(desktop){
								padding-left: 10px;
							}
						}
					}
					.barra{
						left: 210px;
						top: 17px;
						@include respondTo(desktop){
							left: 175px;
						}						
					}
					.flecha{
						background: url('../img/flecha_negra_abajo.png') no-repeat transparent;
						background-position: center center;
						width: 37px;
						height: 29px;
						position: absolute;
						float: right;
						z-index: 1;
						margin-top: -48px;
						margin-left: 130px;
						@include transition(0.4s);
						@include respondTo(desktop){
							margin-left: 100px;
						}
					}
				}
				.valor{
					display: block;
					height: 70px;
					width: 300px;
					background: $blanco;
					float: right;
					position: relative;
					margin-right: 0;
					@include transition(0.6s);
					input{
						@extend %p;
						z-index: 2;
						color: $gris-oscuro;
						border: 0;
						font-size: 11px;
						letter-spacing: 0.43px;
						padding-top: 12px;
						padding-left: 85px;
						margin-top: 5px;
						width: 100%;
						font-weight: 400;
						height: 25px;
					}
					#slider-range{
						position: relative;
						background: $gris-1;
						top: 0;
						z-index: 1;
						margin: 15px 33px;
						height: 2px;
						border: 0;
						.ui-slider-range{
							background: $gris-oscuro;
						}
						.ui-slider-handle{
							width: 16px;
							height: 16px;
							background: $gris-oscuro;
							border: 0;
							top: -7px;
							@include border-radius(8px);
							@include respondTo(desktop){
								width: 24px;
								height: 24px;
								@include border-radius(12px);
								top: -11px;
							}
						}
					}
				}
			}
		}





		.botones{
			position: absolute;
			width: 100%;
			height: 10px;
			z-index: 9;
			display: block;
			margin-left: 0;
			float: left;
			@include respondTo(tablet){
				display: none;
			}
			ul.botonera{
				li{
					width: 12px;
					height: 12px;
					display: block;
					position: relative;
					margin-top: 0;
					float: left;
					border: 2px solid $gris-2;
					margin: 0 7px;
					cursor: pointer;
					@include border-radius(6px);
					@include transition(0.3s);
					&.active{
						border: 2px solid $blanco;
					}
				}
			}
		}






		.filtros-mobile{
			position: absolute;
			width: 100%;
			@include respondTo(notablet){
				display: none;
			}
			.container{
				.row{
					position: relative;
					div{
						z-index: 4;
						position: relative;
						display: block;
						h2{
							margin-top: 70px;
							font-family: $font;
							font-size: 20px;
							color: $blanco;
							letter-spacing: 0.75px;
						}
						fieldset{
							width: 300px;
							margin-left: auto;
							margin-right: auto;
							select{
								@extend %p;
								@include border-radius(0px);
								background: rgba(0,0,0,0.55);
								border: 2px solid $gris-1;
								color: $blanco;
								font-family: $font;
								font-size: 14px;
								letter-spacing: 0.53px;
								padding: 12px;
								text-align: center;
								width: 100%;
								outline: none;	
								appearance:none;
								-moz-appearance:none; 
								-webkit-appearance:none; 					
									&:-moz-focusring {
								    color: transparent;
								    text-shadow: 0 0 0 #000;
								}
							}
							.formHolder{
								@extend %p;
								@include border-radius(0px);
								background: transparent;
								border: 2px solid $gris-1;
								color: $blanco;
								font-family: $font;
								font-size: 14px;
								letter-spacing: 0.53px;
								padding: 12px;
								text-align: center;
								width: 100%;
								z-index: 9999999;
								ul{
									@extend %p;
									@include border-radius(0px);
									background: $blanco;
									color: $gris-oscuro;
									font-family: $font;
									font-size: 14px;
									letter-spacing: 0.53px;
									text-align: center;
									width: calc(100% + 28px);
									margin-left: -14px;
									margin-top: 14px;
									position: absolute;
									z-index: 9999999;
									li{
										z-index: 9999999;
										padding: 10px;
										position: relative;
									}
								}
							}
							label{
								padding: 12px;
							}
							&.barrio{
								margin-top: 50px;
								.flecha{
									background: url('../img/flecha_blanca_abajo.png') no-repeat;
									width: 16px;
									height: 9px;
									top: -27px;
									right: -264px;
									@include transition(0.4s);
								}
							}
							&.tipo{
								margin-top: 17px;
								//margin-left: 2px;
								label{
									@extend %p;
									@include border-radius(0px);
									background: rgba(0,0,0,0.55);
									font-size: 14px;
									letter-spacing: 0.53px;
									color: $blanco;
									width: calc(50% + 1px);
									float: left;
									border: 2px solid $gris-1;
									display: block;
									margin: 0;
									margin-top: -2px;
									margin-left: -2px;
									left: 2px;
									@include box-shadow(0, 0, 0, 0, rgba(153,97,153,0.5));
									@include transition(color 0.3s);
									@include transition(background 0.3s);
									&.full-width{
										width: 100%;
									}
									&.ui-state-active{
										background: $blanco;
										border: 2px solid $blanco;
										color: $dorado-logo;
										z-index: 6;
										@include transition(color 0.3s);
										@include transition(background 0.3s);
									}
								}
							}
						}
						.mas{
							margin: auto;
							margin-top: 30px;
							cursor: pointer;
							.barra{
								top: 19px;
								left: 10px;
								&.ver{
									top: 17px;
								}
							}
						}
					}
					.linea-separadora{
						margin-top: 30px;
						border-top: 2px solid rgba(255,255,255,0.15);
						width: 90%;
						max-width: 700px;
						margin-left: auto;
						margin-right: auto;
						opacity: 0;
					}
				}
				.toggle-mobile{
					margin-top: -290px;
					opacity: 0;
					.row{
						.dormitorios{
							h2{
								margin-top: 30px;
								width: 90%;
								max-width: 700px;
								margin-left: auto;
								margin-right: auto;
							}
						}
						.dormitorios{
							label{
								width: (100% / 6);
								@extend %p;
								@include border-radius(0px);
								background: rgba(0,0,0,0.4);
								font-size: 14px;
								letter-spacing: 0.53px;
								color: $blanco;
								width: calc( (100% / 6) + 1.7px);
								float: left;
								border: 2px solid $gris-1;
								display: block;
								margin: 0;
								margin-top: -2px;
								margin-left: -2px;
								left: 2px;
								outline: none;
								@include box-shadow(0, 0, 0, 0, rgba(153,97,153,0.5));
								@include transition(0.3s);
								&.full-width{
									width: 100%;
									margin-top: 30px;
								}
								&.ui-state-active{
									background: $blanco;
									border: 2px solid $blanco;
									color: $dorado-logo;
									z-index: 6;
									@include transition(0.3s);
								}
							}
						}
						.valor{
							input{
								@extend %p;
								border: 0;
								color: $blanco;
								background: transparent;
								font-size: 11px;
								font-weight: 400;
								height: 25px;
								letter-spacing: 0.43px;
								margin-top: 30px;
								//padding-left: 85px;
								text-align: center;
								padding-top: 12px;
								width: 100%;
								z-index: 2;
							}
							#slider-range-mobile{
								position: relative;
								background: $gris-1;
								top: 0;
								z-index: 1;
								margin: 25px 20px 15px 12px;
								height: 2px;
								border: 0;
								.ui-slider-range{
									background: $blanco;
								}
								.ui-slider-handle{
									width: 30px;
									height: 30px;
									background: $blanco;
									border: 0;
									top: -14px;
									@include border-radius(15px);
								}
							}
						}
					}
				}
			}
		}
	}
}

