/* FORMULARIOS */

.contacto{
	.form{
		//form{
			fieldset {
				position: relative;
				input, textarea{
					@extend %p;
					@include border-radius(0);
					-webkit-appearance: none;
					border: 0;
					border-bottom: 2px solid $gris-oscuro;
					color: $gris-1;
					font-size: 18px;
					margin-bottom: 42px;
					outline: none;
					padding: 8px;
					width: 100%;
					&:focus{
						color: $gris-oscuro;
					}
					&:valid + label,
					&:focus + label{
						color: $gris-oscuro;
						transform: translate3d(0, -25px, 0);
						font-size: 10px;
						letter-spacing: 1px;
					}
					&[type="file"]{
						padding-bottom: 20px;
						float: right;
					}
				}
				textarea{
					resize: none;
					overflow: auto;
					height: 37px;
					position: absolute;
					&:valid,
					&:focus{
						padding-top: 25px;
					}
					&:valid + label.bordes,
					&:focus + label.bordes{
						color: $gris-oscuro;
						transform: translate3d(0, -25px, 0);
						font-size: 10px;
						letter-spacing: 1px;
						background: url('../img/bordes_380.png') no-repeat;
						display: block;
						transition: all 0.3s ease-in-out;
						padding: 15px 0;
						@include background-size(100%);
					}
				}
	  			label {
					@extend %p;
					position: absolute;
					top: 15px;
					left: 8px;
					color: #909090;
					transform: translate3d(0, 0, 0);
					transition: all 0.3s ease-in-out;
					cursor: text;
					span{
						color: red;
					}
				}
				.bordes{
					width: 100%;
					height: 10px;
					left: 0;
					padding-left: 8px !important;
					background: no-repeat;
					position: absolute;
					transition: all 0.3s ease-in-out;
				}
			}
  		//}
	}
}


/* LOGIN */
.users.form{
	p{
		padding: 20px 0;
		color: $gris-oscuro;
	}
	label{
		@extend %p;
		padding-right: 20px;
		margin-bottom: 25px;
	}
	input{
		border: 0;
		border-bottom: 2px solid $gris-3;
		@extend %p;
	}
}















