/* OVERLAY */

.full-overlay {
    height: 0;
    width: 100%;
    position: fixed;
    overflow-y: scroll;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255, 0.95);
    overflow-x: hidden;
    z-index: 11;
    @include transition(opacity 0.8s);
    .container-fluid{
        .row{
            div{
                &.cruz{
                    margin-top: 12px;
                    z-index: 99;
                    a{
                        @extend %p;
                        color: $dorado-logo;
                        font-size: 65px;
                        font-weight: 300;
                        margin-right: 25px;
                    }
                }
                h2{
                    margin-top: 50px;
                }
                fieldset{
                    max-width: 400px;
                    margin-left: auto;
                    margin-right: auto;
                    z-index: 99;
                    select{
                        @extend %p;
                        @include border-radius(0px);
                        z-index: 3;
                        background: transparent;
                        border: 2px solid $gris-oscuro;
                        color: $gris-oscuro;
                        font-family: $font;
                        font-size: 14px;
                        letter-spacing: 0.53px;
                        padding: 12px;
                        text-align: center;
                        width: 100%;
                        outline: none;  
                        appearance:none;
                        -moz-appearance:none; 
                        -webkit-appearance:none;                    
                            &:-moz-focusring {
                            color: transparent;
                            text-shadow: 0 0 0 #000;
                        }
                    }
                    &.barrio{
                        margin-top: 45px;
                        z-index: 99;
                        position: relative;
                        select{
                            option{
                                &:checked{
                                    background: $gris-oscuro;
                                }
                            }
                        }
                    }
                    /*

                    &.barrio{
                        margin-top: 45px;
                        z-index: 99;
                        position: relative;
                        ul{
                            @extend %p;
                            @include border-radius(0px);
                            z-index: 3;
                            background: transparent;
                            color: $gris-oscuro;
                            font-family: $font;
                            font-size: 14px;
                            letter-spacing: 0.53px;
                            text-align: center;
                            width: 100%;
                            outline: none;  
                            appearance:none;
                            -moz-appearance:none; 
                            -webkit-appearance:none;  
                            @include transition(0.3s);                  
                            &:-moz-focusring {
                                color: transparent;
                                text-shadow: 0 0 0 #000;
                            }
                            li{
                                //width: 400px;
                                z-index: 2;
                                height: 40px;
                                cursor: pointer; 
                                padding-top: 12px;
                                @include transition(0.3s);
                                &:not(.init){
                                    display: none;
                                    //position: fixed;
                                }
                                &:not(.init):hover,
                                .selected:not(.init){
                                    background: $gris-oscuro;
                                    color: $blanco;
                                    @include transition(0.3s);
                                }
                                &.init { 
                                    cursor: pointer; 
                                    border: 2px solid $gris-oscuro;
                                }
                            }
                        }
                    }
                    */


                    &.tipo,
                    &.operacion{
                        margin-top: 30px;
                        margin-bottom: 35px;
                        z-index: 99;
                        position: relative;
                        //margin-left: 2px;
                        label{
                            @extend %p;
                            @include border-radius(0px);
                            background: transparent;
                            border: 2px solid $gris-oscuro;
                            color: $gris-oscuro;
                            display: block;
                            float: left;
                            font-size: 14px;
                            height: 40px;
                            letter-spacing: 0.53px;
                            width: calc(50% + 1px);
                            margin: 0;
                            margin-top: -2px;
                            margin-left: -2px;
                            left: 2px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            padding: 10px;
                            @include box-shadow(0, 0, 0, 0, rgba(153,97,153,0.5));
                            @include transition(color 0.3s);
                            @include transition(background 0.3s);
                            &.full-width{
                                width: 100%;
                            }
                            &.ui-state-active{
                                background: $gris-oscuro;
                                border: 2px solid $gris-oscuro;
                                color: $blanco;
                                z-index: 6;
                                @include transition(color 0.3s);
                                @include transition(background 0.3s);
                            }
                        }
                    }
                    &.operacion{
                        margin-bottom: 0;
                    }
                }
                .mas-overlay{
                    margin-top: 0;
                }                
                .mas{
                    margin: 0 auto 30px auto;
                    border: 2px solid $gris-oscuro;
                    @include box-shadow(0, 0, 0, 0, rgba(153,97,153,0.5));
                    .barra{
                        //margin: auto;
                        background: $gris-oscuro;
                        margin-top: 17px;
                        margin-left: 8px;
                        &.ver{
                            margin-top: 17px;
                        }
                    }
                }
                button.buscar-overlay{
                    max-width: 400px;
                    background: transparent;
                    border: 2px solid $gris-oscuro;
                    margin-bottom: 50px;
                    position: relative;
                    &:hover{
                        background: $gris-oscuro;
                        color: $blanco;
                    }
                }
            }
            &.barra{
                div{
                    padding-left: 0;
                    padding-right: 0;
                    .barra-separadora{
                        background: $gris-3;
                        //margin-top: 50px;
                        width: 100%;
                        height: 2px;
                        opacity: 0;
                        z-index: 1;
                    }
                }
            }
        }
        .toggle-overlay{
            margin-top: -340px;
            position: absolute;
            width: 100%;
            opacity: 0;
            //display: none;
            z-index: 1;
            .dormitorios{
                position: relative;
                margin-left: auto;
                margin-right: auto;
                z-index: 0;
                h2{
                    margin-top: 30px;
                    width: 90%;
                    max-width: 700px;
                    margin-left: auto;
                    margin-right: auto;
                }
                label{
                    width: (100% / 6);
                    @extend %p;
                    @include border-radius(0px);
                    background: transparent;
                    font-size: 14px;
                    letter-spacing: 0.53px;
                    color: $gris-oscuro;
                    width: calc( (100% / 6) + 1.7px);
                    float: left;
                    border: 2px solid $gris-oscuro;
                    display: block;
                    height: 40px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin: 0;
                    margin-top: -2px;
                    margin-left: -2px;
                    left: 2px;
                    background: $blanco;
                    @include box-shadow(0, 0, 0, 0, rgba(153,97,153,0.5));
                    @include transition(0.3s);
                    &.full-width{
                        width: 100%;
                        margin-top: 30px;
                    }
                    &.ui-state-active{
                        background: $gris-oscuro;
                        border: 2px solid $gris-oscuro;
                        color: $blanco;
                        z-index: 6;
                        @include transition(0.3s);
                    }
                }
            }
        
            .valor-overlay{
                z-index: 0;
                input{
                    @extend %p;
                    border: 0;
                    color: $gris-oscuro;
                    background: transparent;
                    font-size: 11px;
                    font-weight: 400;
                    height: 25px;
                    letter-spacing: 0.43px;
                    margin-top: 30px;
                    //padding-left: 135px;
                    padding-top: 12px;
                    text-align: center;
                    width: 100%;
                    z-index: 2;
                }
                #slider-range-overlay{
                    position: relative;
                    background: $gris-1;
                    top: 0;
                    z-index: 1;
                    margin: 25px 20px 20px 12px;
                    height: 2px;
                    border: 0;
                    .ui-slider-range{
                        background: $gris-oscuro;
                    }
                    .ui-slider-handle{
                        width: 30px;
                        height: 30px;
                        background: $gris-oscuro;
                        border: 0;
                        top: -14px;
                        @include border-radius(15px);
                    }
                }
            }

            .referencia{
                input{
                    @extend %p;
                    @include border-radius(0);
                    -webkit-appearance: none;
                    border: 0;
                    border-bottom: 2px solid $gris-oscuro;
                    color: $gris-oscuro;
                    font-size: 14px;
                    margin-top: 35px;
                    outline: none;
                    padding: 8px;
                    width: 100%;
                    text-align: center;
                    &::-webkit-input-placeholder {color: $gris-oscuro;}
                    &:-moz-placeholder           {color: $gris-oscuro;}
                    &::-moz-placeholder          {color: $gris-oscuro;}
                    &:-ms-input-placeholder      {color: $gris-oscuro;}  
                    &:focus{
                        color: $gris-oscuro;
                    }
                }
            }
        }
    }
}