/* MIXINS */

@mixin transition($args) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}
// @include transition(tablet);

// Breakpoints for each query
$smartphone: 480px;
$tablet: 767px;
$desktop: 992px;
$huge: 1280px;
@mixin respondTo($media) {
  @if $media == smartphone {
    @media screen and (max-width: $smartphone) { @content; }
  }
  @else if $media == tablet {
    @media screen and (max-width: $tablet) { @content; }
  }
  @else if $media == desktop {
    @media screen and (max-width: $desktop) { @content; }
  }  
  @else if $media == huge {
    @media screen and (max-width: $huge) { @content; }
  }
  @else if $media == nosmartphone {
    @media screen and (min-width: $smartphone +1 ) { @content; }
  }
  @else if $media == notablet {
    @media screen and (min-width: $tablet + 1) { @content; }
  }
  @else if $media == nodesktop {
    @media screen and (min-width: $desktop + 1) { @content; }
  }
}
// @include respondTo(tablet);


@mixin push--auto {
    margin: { 
        left: auto;
        right: auto;
    }
}
// @include push--auto;


@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
}
/*
@include placeholder {
    font-style:italic;
    color: white;
    font-weight:100;
}
*/

@mixin border-radius($arg) {
	-webkit-border-radius: $arg; 
	-moz-border-radius: $arg; 
	border-radius: $arg; 
}
// @include border-radius(5px);


@mixin box-shadow($hor, $ver, $rad, $spre, $rgba){
	-webkit-box-shadow: $hor $ver $rad $spre $rgba;
	-moz-box-shadow: $hor $ver $rad $spre$spre $rgba;
	box-shadow: $hor $ver $rad $spre $rgba;
}
// @include box-shadow(58px, 25px, 25px, -153px, rgba(153,97,153,0.5));


@mixin visuallyhidden() {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
  display: none;
}
// @include visuallyhidden();


@mixin rotar($angulo){
	//$angulo: unquote($angulo);
	-webkit-transform: rotate($angulo + deg);
	-moz-transform: rotate($angulo + deg);
	-ms-transform: rotate($angulo + deg);
	-o-transform: rotate($angulo + deg);
	transform: rotate($angulo + deg);
}
// @include rotar(90);


@mixin flecha($direccion){

	background: url('../img/flecha.png') no-repeat;
	width: 14px;
	height: 27px;
	display: block;

	@if $direccion == izquierda {
		
	} @else if $direccion == arriba {
		@include rotar(90);
	} @else if $direccion == derecha {
		@include rotar(180);
	} @else if $direccion == abajo {
		@include rotar(270);
	}
}


@mixin transition($transition) {
	-moz-transition:    $transition;
	-o-transition:      $transition;
	-webkit-transition: $transition;
	transition:         $transition;
  -ms-transition:     $transition;
}
// @include transition(0.3s);


@mixin background-size($size) {
  -webkit-background-size: $size;
  -moz-background-size: $size;
  -o-background-size: $size;
  background-size: $size;
}
// @include background-size(100%);

@mixin rotar360($tiempo){
  -webkit-animation:spin $tiempo linear infinite;
  -moz-animation:spin $tiempo linear infinite;
  animation:spin $tiempo linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

// @include rotar360(4s);


@mixin scrollbars($size, $foreground-color, $background-color) {
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }

}

//@include scrollbars(10px, $azul-logo, $blanco);




