/* PRINT */


header,
section.migas,
.cta,
.galeria,
.botones,
.icono,
.mapa,
section.similares,
footer,
.codigo i
{
	@include visuallyhidden;
}

.datos{
	margin-top: 70px !important;
}
section.ubicacion{
	margin-bottom: 100px;
}
.alquiler-venta p{
	font-size: 20px !important;
}
div.foto_principal_print{
	display: block;
	img{
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}
section.header_imprimible{
	margin: 55px;
	display: block;
	.pull-right{
		p{
			@extends %p;
			text-align: right;
			padding-bottom: 5px;
			padding-top: 5px;
			font-size: 0.9em;
			font-weight: 100;
		}
	}
}
section.footer_imprimible{
	margin: 55px;
	display: block;
}
