/* PROPIEDADES */


section.filtros.primera{
	background: $gris-oscuro;
	font-family: $font;
	color: $gris-2;
	letter-spacing: 0.53px;
	margin-top: 40px;
	height: 75px;
	@include respondTo(desktop){
		display: none;
	}
	.container-fluid{
		.row{
			margin-right: 55px;
			margin-left: 55px;
			fieldset{
				margin-top: 28px;
				width: calc((100%  / 3); //barrio
				float: left;
				label{
					font-size: 14px;
					letter-spacing: 0.53px;
					float: left;
					padding-top: 3px;
					@include respondTo(desktop){
						font-size: 12px;
					}
				}
				&.operacion{
					margin-left: -10px;
					margin-right: 10px;
					@include respondTo(desktop){
						width: calc((100%  / 3);
					}
				}				
				&.tipo{
					@include respondTo(desktop){
						width: calc((100%  / 3);
					}
				}
				.barra{
					background: $gris-1;
					width: 1px;
					height: 18px;
					margin-left: 13px;
					margin-right: 13px;
					top: 5px;
					float: left;
					@include respondTo(desktop){
						margin-left: 10px;
						margin-right: 10px;
					}
				}
				select{
					background: transparent;
					border: 0;
					color: $blanco;
					width: 150px;
					outline: none;
					margin-top: -1px;
					float: left;
					@include respondTo(desktop){
						font-size: 14px;
						width: 120px;
					}
					option{
						border: 0;
					}
				}
				input{
					background: transparent;
					border: 0;
					color: $blanco;
					width: 150px;
					outline: none;
					margin-top: -1px;
					float: left;
				}
				.flecha{
					background: url('../img/flecha_blanca_abajo.png') no-repeat;
					width: 16px;
					height: 9px;
					position: inline-block;
					float: left;
					margin-top: 6px;
					margin-left: 0px;
					z-index: 1;
					cursor: pointer;
					@include transition(0.4s);
				}
				.formHolder{
					display: inline-block;
					float: left;
					width: 150px;
					cursor: pointer;
					margin-top: 2px;
					z-index: 3;
					@include scrollbars(10px, $gris-1, $blanco);
					ul{
						background: $blanco;
						border: 0;
						color: $gris-oscuro;
						width: 210px;
						outline: none;
						margin-top: -1px;
						float: left;
						position: absolute;
						margin-left: 0px;
						margin-top: 10px;
						display: none;
						cursor: pointer;
						z-index: 7;
						max-height: 170px;
						overflow-y: auto;
						@include box-shadow(5px, 5px, 5px, 2px, rgba(0,0,0,0.3));
						@include respondTo(desktop){
							font-size: 14px;
							width: 120px;
						}
						li{
							border: 0;
							cursor: pointer;
							padding: 10px 5px 10px 15px;
							font-size: 14px;
							@include transition(0.3s);
							&:hover{
								background: $gris-oscuro;
								color: $blanco;
								@include transition(0.3s);
							}
						}
					}
				}
			}
			.right{
				width: 7%;
				margin-top: 15px;
				margin-bottom: 15px;
				button{
					i{
						margin-left: 0px;
					}
				}
			}
		}
	}
}

.right.lupa{
	width: 7%;
	margin-top: -120px;
	margin-bottom: 15px;
	margin-right: 55px;
	button{
		i{
			margin-left: 0px;
		}
	}
	@include respondTo(desktop){
		display: none;
	}
}



section.filtros.segunda{
	background: $blanco;
	border-bottom: 1px solid $gris-3;
	//padding-bottom: 30px;
	@include respondTo(desktop){
		display: none;
	}
	.container-fluid{
		.row{
			margin-right: 55px;
			margin-left: 55px;
			.wrapper{
				margin-right: auto;
				margin-left: 0;
				margin-top: 30px;
				width: 85%;
				@include respondTo(desktop){
					width: 710px;
				}		
				@include respondTo(huge){
					width: 97%;
				}									
				fieldset{
					float: left;
					margin-bottom: 30px;
					@include respondTo(desktop){
						//clear: both;
					}					
					&.dormitorios{
						width: 45%;
						float: left;
						display: inline;
						//min-width: 377px;
						@include respondTo(desktop){
							//width: 266px;
							margin-right: 30px;
							//margin-left: 60px;
						}					
					}
					&.valor{
						display: inline;
						width: 320px;
						//float: right;
						input{
							@extend %p;
							float: left;
							text-align: left;
							margin-left: 20px;
							//margin-right: calc(70% - 223px);
							position: relative;
							border: 0;
							border-bottom: 2px solid $gris-oscuro;
							font-size: 12px;
							color: $gris-oscuro;
							letter-spacing: 1.75px;
							padding-bottom: 5px;
							margin-top: 7px;
							height: 21px;
							width: 130px;
						}
					}
					&.ref{
						//margin-right: 50px;
						display: inline;
						width: 140px;
						float: right;
						input{
							@extend %p;
							float: right;
							text-align: left;
							//margin-left: -20px;
							//margin-right: calc(70% - 223px);
							position: relative;
							border: 0;
							border-bottom: 2px solid $gris-oscuro;
							font-size: 12px;
							color: $gris-oscuro;
							letter-spacing: 1.75px;
							padding-bottom: 5px;
							margin-top: 7px;
							height: 21px;
						}
					}
					legend{
					    @extend %p;
						font-size: 14px;
						color: #3D3D3D;
						letter-spacing: 0.53px;
						float: left;
						width: 110px;
						margin-top: 10px;
						@include respondTo(desktop){
							//width: 100%;
							//text-align: center;
							//padding-bottom: 20px;
						}					
					}
					@extend %check-box-mobile;
					label{
						float: left;
						width: 40px;
						height: 40px;
						padding-top: 9px;
						border: 2px solid $gris-3;
						font-size: 14px;
						color: #979797;
						letter-spacing: 0.52px;						
						&.last-child{
							padding-left: 10px;
							padding-right: 8px;
						}
						&.ui-state-active{
					      background: $gris-oscuro;
					      border: 2px solid $gris-oscuro;
					      color: $blanco;
						}
					}
				}
				.right{
					float: right;
					width: 55%;
					@include transition(0.6s);
				}
			}
		}
	}
}

section.migas{
	border: 0;
	@include respondTo(tablet) {
		border-top: 1px solid $gris-3;
	}
	.row{
		.right{
			@include respondTo(tablet){
				display: none;
			}
			select{
				margin-top: 50px;
				@extend %p;
				font-size: 14px;
				color: $gris-oscuro;
				letter-spacing: 0.53px;
				width: 150px;
				display: inline-block;
			}
			.formHolder{
				display: inline-block;
				margin-top: 50px;
				margin-right: 30px;
				padding-right: 5px;
				@extend %p;
				font-size: 14px;
				color: $gris-oscuro;
				letter-spacing: 0.53px;
				width: 110px;
				cursor: pointer;
				ul{
					margin-top: 10px;
					@extend %p;
					font-size: 14px;
					color: $gris-oscuro;
					letter-spacing: 0.53px;
					width: 130px;
					position: absolute;
					z-index: 5;
					background: $blanco;
					@include box-shadow(5px, 5px, 5px, 2px, rgba(0,0,0,0.3));
					li{
						padding: 10px 15px 10px 15px;
						cursor: pointer;
						z-index: 5;
						background: $blanco;
						float: left;
						width: 130px;
						text-align: center;
						@include transition(0.3s);
						&:hover{
							background: $gris-oscuro;
							color: $blanco;
							@include transition(0.3s);
						}
					}
				}
			}
			.flecha{
				background: url('../img/flecha_negra_abajo_chica.png') no-repeat;
				width: 20px;
				height: 11px;
				display: inline-block;
				margin-right: 55px;
				margin-left: -25px;
				@include transition(0.4s);
			}
		}
	}
}

section.busqueda-mobile{
	@include respondTo(nodesktop){
		display: none;
	}
	margin-top: 40px;
	border-bottom: 1px solid $gris-3;
	padding-bottom: 40px;
	.container{
		.row{
			div{
				p{
					@extend %p;
					font-size: 18px;
					color: $gris-oscuro;
					letter-spacing: 2.62px;
					line-height: 26px;
					font-weight: 400;
				}
				button{
					margin-top: 25px;
					@include box-shadow(0px, 0px, 0px, 0px, rgba(0,0,0,0.0));
					i{

					}
				}
			}
		}
	}
}

section.propiedades{
	.container-fliud{
		margin-left: 55px;
		margin-right: 55px;
		@include respondTo(tablet){
			margin-left: 30px;
			margin-right: 30px;
		}
		.row{
			.propiedad{
				//background: #dd0000;
				padding: 0;
				float: left;
				margin-top: 50px;
				overflow-y: hidden;
				height: 300px;
				border: 1px solid $gris-3;
				width: calc((100% - 140px)/3);
				&:nth-child(3n+1){
					margin-right: 35px;
				}
				&:nth-child(3n+2){
					margin-right: 35px;
					margin-left: 35px;
				}
				&:nth-child(3n+3){
					margin-left: 35px;
				}
				@include respondTo(desktop){
					width: calc((100% - 70px)/2);
					&:nth-child(2n+1){
						margin-right: 35px;
						margin-left: 0;
					}
					&:nth-child(2n+2){
						margin-left: 35px;
						margin-right: 0;
					}
				}
				@include respondTo(tablet){
					width: 100%;
					&:nth-child(2n+1){
						margin-right: 0;
						margin-left: 0;
					}
					&:nth-child(2n+2){
						margin-right: 0;
						margin-left: 0;
					}
				}
				.img-bg{
					background: url('../img/casa-skeleton.png') no-repeat center center $gris-3;
					.imagen{
						width: 100%;
						height: 240px;
						background-transparent: transparent;
						z-index: 1;
						background-size: cover;
						background-position: center center;
					}
				}
				.textos{
					width: 100%;
					height: 150px;
					background: $blanco;
					z-index: 2;
					@include transition(0.5s);
					.row{
						margin-left: 22px;
						margin-right: 22px;
						@extend %p;
						color: $gris-oscuro;
						@include respondTo(tablet){
							margin-left: 15px;
							margin-right: 15px;
						}
						&.grande{
							padding-top: 10px;
							font-size: 18px;
							letter-spacing: 0.68px;
							@include respondTo(huge){
								font-size: 14px;
							}
							@include respondTo(desktop){
								font-size: 16px;
							}							
						}
						&.chica{
							padding-top: 5px;
							font-size: 12px;
							letter-spacing: 0.45px;						
						}
						.cta{
							@extend %button;
							display: block;
							text-decoration: none;
							background: $blanco;
							color: $gris-oscuro;
							font-size: 14px;
							letter-spacing: 2.33px;
							margin-top: 24px;
							padding-top: 15px;
							&:hover{
								background: $gris-oscuro;
								color: $blanco;
							}
						}
					}
				}
				@include respondTo(notablet){
					&:hover .textos{
						margin-top: -90px;
						@include transition(0.4s);
					}
				}
			}
		}
	}
}

section.spinner{
	margin-top: 80px;
	.container{
		.row{
			div{
				i{
					color: $gris-1;
					@include rotar360(2s);
				}
			}
		}
	}
}

footer.propiedades-footer{
	margin-top: 50px;
}



/* PROPIEDADES ADD */
.agregar_propiedad{
	label{
		margin-top: 7px;
		color: $gris-1 !important;
		transform: translate3d(0, -25px, 0) !important;
		font-size: 10px !important;
	}
	input[type="text"],
	input[type="number"],
	select{
		height: 40px;
		background: #EEEEEE;
		margin-top: 10px;
		border-bottom: 2px solid $gris-3 !important;
	}
	input[type="file"] {
	    //display: none;
	    opacity: 0;
	    height: 0;
	    width: 0;
	    padding: 0;
	    margin: 0;
	}
	label[for="visible"]{
		@extend %p;
		margin-top: 20px;
		font-size: 14px !important;
		input{
			margin-top: 0;
		}
	}
	.custom-file-upload {
		margin-top: 20px;
	    border: 2px solid $gris-3;
	    display: inline-block;
	    cursor: pointer !important;
	    position: relative;
	    display: block;
	    float: left;
	    &[for="foto-principal"]{
		    padding: 30px 30px;
	    }
	    &[for="fotos-secundarias"]{
		    padding: 15px 15px;
	    }
	}
	.imgP{
		position: relative;
		float: left;
		margin-left: 110px;
		margin-top: 10px;
		display: inline-block;
	}
	.imgS{
		position: relative;
		float: left;
		margin-left: 70px;
		margin-top: 10px;
		display: inline-block;
	}
	.rutas{
	    position: relative;
		float: left;
	    display: block;
	    margin-left: 10px;
	    @extend %p;
	    font-size: 12px;
	    letter-spacing: 1.5px;
	    &#rutaPrincipal{
	    	margin-top: -90px;
			margin-left: 130px;
	    }
	    &#rutaSecundarias{
	    	margin-top: -60px;
	    	margin-left: 90px;
	    }
	}
	table{
		td{
			border: 0 !important;
			div{
				padding: 30px 0 0 0;
				height: 0;
				label{
					margin: 0;
					padding: 0;
					font-size: 14px !important;
					margin-left: 40px !important;
					margin-bottom: 0 !important;
				}
				input{
					padding: 0;
					margin: 0;
					margin-right: 20px !important;
					margin-top: 2px !important;
				}
			}
		}
	}
	.input.text.required,
	.input.checkbox{
		label{
			@extend %p;
			//position: absolute;
			top: 15px;
			left: 8px;
			color: #909090;
			transform: translate3d(0, 0, 0);
			transition: all 0.3s ease-in-out;
			cursor: text;
			span{
				color: red;
			}
		}
		input[type="text"]{
			height: 40px;
		}
	}
	.checkbox{
		margin-bottom: -20px;
	}
	table{
		.checkbox{
			margin-bottom: 10px;
		}
	}
	fieldset.descripcion{
		div{
			textarea{
				margin-top: 10px;
				padding: 10px;
				padding-top: 10px !important;
				height: 150px !important;
				border-bottom: 2px solid $gris-3 !important;
				background: #EEEEEE;
				background-size: 100%;
			}
		}
	}
	#parrillero{
		margin-bottom: 30px;
		display: block;
	}
	#foto-principal,
	#fotos-secundarias,
	#file{
		padding-left: 200px;
	}
	#foto-principal,
	#file{
		padding-bottom: 50px;
	}
	.venta_o_alquiler{
		border-bottom: 2px solid $gris-3; 
		margin-bottom: 50px; 
		padding-bottom: 8px;
		height: 30px;
		margin-top: 20px;
		input{
			margin-left: 20px;
			margin-right: 30px;
			//margin-top: 15px;
			display: block;
			float: left;
			position: relative;
		}
		label{
			padding-left: 8px;
			font-size: 14px !important;
			margin-top: 10px;
			display: block;
			position: relative;
			float: left;
		}
	}
	.agregar_propiedad,
	.editar_propiedad{
		input{
			border-bottom: 2px solid $gris-3 !important; 
		}
	}
	#barrio-drop{
		label{
			display: block;
			position: relative;
			//margin-top: -25px;
			//margin-bottom: 25px;
			color: $gris-1;
			font-size: 10px;
			letter-spacing: 1px;
			margin-bottom: -6px !important;
		}
		select{
			border-bottom: 2px solid $gris-3; 
			width: 100%;
			@extend %p;
			option{
				//@extend %p;
			}
		}
	}
}






/* PROPIEDADES EDIT */
h4{
	background: $azul-logo;
	padding: 10px;
	font-size: 18px;
	color: $blanco;
}

ul.side-nav{
	li{
		display: inline-block;
		@include respondTo(tablet){
			margin-top: 25px;
			width: 48%;
		}
		@include respondTo(smartphone){
			width: 100%;
		}
		a{
			@extend %button;
			text-decoration: none !important;
			width: 100%;
			color: $gris-oscuro;
			display: inline-block;
			padding-top: 14px;
		}
	}
}
.fotos{
	width: 42px;
	height: 42px;
	margin: 5px;
	//margin-bottom: 50px;
	margin-top: 0;
}
#barrio-drop{
	label{
		width: 50%;
	}
	select{
		margin: 10px 0 50px 0;
		margin-top: 0;
		width: 100%;
	}
}
.detalles{
	margin-top: 50px;
	margin-bottom: 50px;
	label{
		@extend %p;
		padding-left: 8px;
		color: $gris-1;
		font-size: 16px;
		letter-spacing: 3.34px;
		margin-bottom: 20px;
	}
	table.table{
		margin-bottom: 0;
	}
}


/* ADMIN CONSOLE PROPIEDADES */

.admin-console-propiedades{
	ul{
		margin-bottom: 25px;
	}
	form{
		margin-bottom: 40px;
		p{
			display: inline-block;
		}
		input{
			@extend %p;
			padding: 5px;
			background: $blanco;
			border: 1px solid $gris-2;
		}
	}
	table{
		th{
			text-align: center;
		}
	}
	table,
	.paginator{
		text-align: center;
		@extend %p;
		color: $gris-oscuro;
	}
	.paginator{
		margin-bottom: 50px;
	}
}

