/* LAYOUT */

h1{
	font-family: $font;
	font-size: 36px;
	color: #242424;
	letter-spacing: 5.25px;
	text-align: center;
	@include respondTo(tablet) {
		font-size: 36px;
	}
	@include respondTo(samrtphone) {
		font-size: 18px;
	}
}

h2{
	font-family: $font;
	font-size: 18px;
	color: $gris-oscuro;
	letter-spacing: 2.62px;
	line-height: 26px;
}
h3{
	font-family: $font;
	font-size: 24px;
	color: $gris-oscuro;
	letter-spacing: 3.5px;
	@include respondTo(desktop){
		text-align: center;
	}
}
h4{
	font-family: $font;
	font-size: 14px;
	color: $gris-oscuro;
	letter-spacing: 3.34px;
}
a[href^=tel] { 
	color: inherit; 
	text-decoration: none; 
}
p{
	font-family: $font;
	font-size: 14px;
	color: $gris-1;
	letter-spacing: 3.34px;
	text-decoration: none;
}
%p{
	font-family: $font;
	font-size: 14px;
	color: $gris-1;
	letter-spacing: 3.34px;
	text-decoration: none;
}

.left{
	float: left;
	text-align: left;
}

.right{
	float: right;
	text-align: right;
}

.clr{
	@extend %clearfix;
}

%menu{
	font-family: $font;
	font-size: 18px;
	color: $gris-oscuro;
	letter-spacing: 1px;
	@include transition(0.3);
	@include respondTo(desktop){
		font-size: 14px;
	}
	&:hover{
		color: $dorado-logo !important;
		text-decoration: none;
		@include transition(0.3);
	}
	&:link{
		color: inherit;
		text-decoration: none;
	}
	&:visited{
		color: inherit;
		text-decoration: none;
	}
	&:active{
		color: inherit;
		text-decoration: none;
	}
}

@include scrollbars(10px, $gris-3, $blanco);