#overlay-agradecimiento{
	.caja{
		@include box-shadow(10px, 10px, 30px, 10px, rgba(0,0,0,0.5));
		margin-top: 100px;
		background-color: rgba(255,255,255,1);
		h2{
			margin-top: 0;
			margin-bottom: 30px;
			font-size: 18px;
			font-weight: bold;
			color: $gris-oscuro;
			letter-spacing: 3.25px;
		}
		p{
			font-size: 14px;
			color: $gris-oscuro;
			letter-spacing: 3.5px;
			margin-bottom: 30px;
			line-height: 1.7;
		}
	}
}

