/* BOTONES */

button{
	width: 100%;
	height: 50px;
	background: $blanco;
	border: 2px solid $gris-oscuro;
	color: $gris-oscuro;
	font-family: $font;
	letter-spacing: 1.5px;
	font-weight: bold;
	font-size: 14px;
	outline: none;
	@include transition(color 0.3s);
	@include transition(background 0.3s);
	&:hover{
		background: $gris-oscuro;
		color: $blanco;
		@include transition(color 0.3s);
		@include transition(background 0.3s);
		outline: none;
	}
}
%button{
	width: 100%;
	height: 50px;
	background: $blanco;
	border: 2px solid $gris-oscuro;
	color: $gris-oscuro;
	font-family: $font;
	letter-spacing: 1.5px;
	font-weight: bold;
	font-size: 14px;
	outline: none;
	@include transition(color 0.3s);
	@include transition(background 0.3s);
	&:hover{
		background: $gris-oscuro;
		color: $blanco;
		@include transition(color 0.3s);
		@include transition(background 0.3s);
		outline: none;
	}
}

button.cta-slider{
	height: 50px;
	width: 185px;
	border: 2px solid $blanco;
	background: transparent;
	color: $blanco;
	letter-spacing: 1.5px;
	margin-top: 20px;
	@include transition(color 0.5s);
	@include transition(background 0.5s);
	&:hover{
		background: $blanco;
		color: $gris-oscuro;
		@include transition(color 0.5s);
		@include transition(background 0.5s);
	}
}

button.buscar{
	width: 200px;
	background: $azul-logo;
	font-family: $font;
	font-size: 18px;
	color: $blanco;
	letter-spacing: 6.3px;
	border: 0;
	height: 65px;
	font-weight: 400;
	position: relative;
	margin: 120px auto 0 350px;
	@include box-shadow(0, 2px, 10px, 0px, rgba(0,0,0,0.35));
	@include transition(color 0.5s);
	@include transition(background 0.5s);
	@include respondTo(desktop){
		margin-left: 250px;
	}
	@include respondTo(tablet){
		margin: auto;
		margin-top: 35px;
	}	
	&:hover{
		background: $blanco;
		color: $azul-logo;
		@include transition(color 0.3s);
		@include transition(background 0.3s);
	}
}

.mas{
	width: 40px;
	height: 40px;
	background: $blanco;
	@include border-radius(20px);
	@include box-shadow(0, 2px, 10px, 0px, rgba(0,0,0,0.35));

	.barra{
		position: absolute;
		height: 2px;
		width: 20px;
		background: $gris-1;
		//top: 19px;
		//left: 10px;
		@include transition(0.7s);
	}
	.barra.ver{
		//top: 17px;
		@include rotar(90);
	}
	&:hover .barra{
		background: $gris-oscuro;
		@include transition(0.5s);
	}
}

button.filtro{
	@include box-shadow(3px, 3px, 8px, 0px, rgba(0,0,0,0.30));
	@include border-radius(25px);
	width: 50px;
	height: 50px;
	background: $blanco;
	@include transition(color 0.3s);
	@include transition(background 0.3s);
	&:hover{
		background: $dorado-logo;
		color: $blanco;
		@include transition(color 0.3s);
		@include transition(background 0.3s);
	}
	i{

	}
}

button.ficha{
	border: 0;
	background: $azul-logo;
	color: $blanco;
	font-size: 18px;
	letter-spacing: 6.3px;
	font-weight: 300;
	height: 75px;
	&:hover{
		background: $blanco;
		color: $azul-logo;
		border: 2px solid $azul-logo;
	}
}

.boton_admin{
	border: 1px solid $gris-3;
	padding: 5px;
	margin-top: 5px;
	display: inline-block;
	background-color: transparent;
	&:hover{
		background-color: $gris-3;
		text-decoration: none;
	}
}



