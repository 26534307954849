/* MIGAS */

section.migas{
	color: $gris-1;
	margin-top: 10px;
	margin-bottom: 10px;
	@include respondTo(tablet) {
		border-top: 1px solid $gris-3;
	}
	.migas-desktop{
		@include respondTo(desktop){
			@include visuallyhidden();
		}
		p{
			margin-left: 55px;
			margin-top: 50px;
			a{
				@extend %p;
			}
			span{
				font-weight: bold;
			}
		}
	}
	.migas-mobile{
		margin-left: 55px;
		margin-top: 45px;
		@include respondTo(nodesktop){
			@include visuallyhidden();
		}
	    @include respondTo(tablet) {
			margin-left: 35px;
		}
		@include respondTo(smartphone) {
			margin-left: 0;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 100px;
		}
		.flecha{
			@include flecha(izquierda);
			display: block;
		}
		div{
			display: block;
			height: 27px;
			margin-top: 0;
			float: left;
			margin-right: 10px;
			padding-top: 5px;
			p{
				margin-bottom: 10px;
			}
		}
	}
}


