/* FOOTER */

// .left, .right width
$lr-width: 35%;
$c-width: 100 - ($lr-width * 2);

footer{
	margin-bottom: 50px;
	.wrap{
		@include respondTo(desktop) {
			@include push--auto;
			width: 620px;
		}
		@include respondTo(tablet) {
			width: 100%;
		}
		.left{
			width: $lr-width;
			margin-left: 105px;
			@include respondTo(desktop) {
				width: auto;
			}
			@include respondTo(tablet) {
				width: 100%;
				margin-left: 0;
			}
			ul{
				@include respondTo(desktop) {
					float: right;
					border-right: 1px solid $gris-1;
					padding-right: 5px;
				}
				@include respondTo(tablet) {
					border: 0;
					width: 100%;
					padding-right: 0;
				}
				li{
					float: left;
					@include push--auto;
					@include respondTo(tablet) {
						width: 100%;
					}
					a{
						@extend %p;
						font-size: 12px;
						padding-right: 35px;
						letter-spacing: 1.28px;
						line-height: 22px;
						color: $gris-oscuro;
						@include respondTo(desktop) {
							padding-right: 30px;
						}
						@include respondTo(tablet) {
							padding-right: 0;
							@include push--auto;
							text-align: center;
							display: block;
							padding-bottom: 15px;
						}
					}
				}
			}
		}
		.right{
			width: $lr-width;
			margin-right: 65px;
			@include respondTo(desktop) {
				width: auto;
				float: left;
			}
			@include respondTo(tablet) {
				width: 100%;
				margin-right: 0;
			}
			ul{
				@include respondTo(desktop) {
					float: right;
					padding-left: 30px;
					margin-left: 0;
				}
				@include respondTo(tablet) {
					width: 100%;
					padding-left: 0;
					float: left;
				}
				li{
					@include respondTo(tablet) {
						width: 100%;
					}
					@include respondTo(notablet) {
						float: right;
					}
					a{
						@extend %p;
						font-size: 12px;
						letter-spacing: 1.28px;
						line-height: 22px;
						color: $gris-oscuro;
						@include respondTo(tablet) {
							@include push--auto;
							text-align: center;
							display: block;
						}
						i{
							color: $gris-2;
							font-size: 24px;
							text-align: center;
							padding-left: 53px;
						}
					}
				}
				li.social{
					@include respondTo(tablet) {
						width: 200px;
						@include push--auto;
						text-align: center;
						clear: both;
						padding-top: 45px;
					}
					a{
						float: left;
						@include push--auto;
						@include respondTo(tablet) {
							float: none;
							text-align: center;
						}
						i{
							@include respondTo(tablet) {
								padding-left: 0;
							}
						}
					}
				}
				li.contacto-footer-nosmartphone{
					@include respondTo(tablet) {
						margin: -1px;
						padding: 0;
						width: 1px;
						height: 1px;
						overflow: hidden;
						clip: rect(0 0 0 0);
						clip: rect(0, 0, 0, 0);
						position: absolute;
  					}
				}
				li.contacto-footer-smartphone{
					@include respondTo(notablet) {
						margin: -1px;
						padding: 0;
						width: 1px;
						height: 1px;
						overflow: hidden;
						clip: rect(0 0 0 0);
						clip: rect(0, 0, 0, 0);
						position: absolute;
  					}
				}
			}
		}
	}
	.center{
		height: auto;
		@include respondTo(desktop) {
			width: 100%;
			float: left;
			margin-top: 35px;
		}
		p{
			width: $c-width;
			@include push--auto;
			@extend %p;
			text-align: center;
			color: $gris-2;
			letter-spacing: 0px;
			line-height: 18px;
			font-size: 13px;
			font-weight: lighter;
			span.osom{
				margin-left: 30px;
				line-height: 23px;
				display: block;
				text-align: center;
				@include respondTo(desktop) {
					margin-left: 0;
				}
				a{
					@include push--auto;
					pointer: cursor;
					display: block;
					position: relative;
					background-image: url('../img/osom_logo.png');
					width: 80px;
					height: 18px;
					background-size: 80px auto;
					text-align: center;
					margin: auto;
				}
			}
		}
	}
}


