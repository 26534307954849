/* ADMIN */

.admin{
	.row{
		div{
			h3{
				padding: 20px 0;
			}
			a{
				@extend %p;
				color: $azul-logo;
				@include transition(0.3s);
				&:link {
				    text-decoration: none;
				}
				&:visited {
				    text-decoration: none;
				}
				&:hover {
				    text-decoration: none;
				    color: $blanco;
				    @include transition(0.3s);
				    .boton{
				    	background: $azul-logo;
				    	border: 2px solid $azul-logo;
					    @include transition(0.3s);
				    	p{
				    		color: $blanco;
						    @include transition(0.3s);
				    	}
				    }
				}
				&:active {
				    text-decoration: none;
				}
				.boton{
					border: 2px solid $gris-oscuro;
					padding: 30px;
					text-align: center;
					display: block;
					margin-top: 30px;
				    @include transition(0.3s);
					p{
						height: 40px;
						padding-top: 20px;
						color: $gris-oscuro;
						display: block;
						vertical-align: middle;
					    @include transition(0.3s);
					}
				}
			}			
		}
	}
}


/* SLIDES INDEX */

.admin-console-slides{
	.slide{
		padding-top: 30px;
		padding-bottom: 30px;
		div{
			.row{
				margin-top: 20px;
				padding-bottom: 20px;
				div{
					@extend %p;
					color: $gris-oscuro;
					font-size: 16px;
					span{
						color: $gris-1;
						font-size: 14px;
						line-height: 20px;
					}
				}
			}
			a{
				font-family: $font;
				width: 100%;
				padding: 20px;
				text-align: center;
				font-size: 14px;
				margin-bottom: 15px;
				@include respondTo(tablet){
					width: 32%;
					margin-bottom: 0;
				}
			}
		}
		.img{
			min-height: 300px;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}
}