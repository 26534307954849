/* HEADER */
header{
	width: 100%;
	background: $blanco;
	.row{
	    @include respondTo(tablet) {
			padding-bottom: 20px;
		}
	    @include respondTo(smartphone) {
			padding-bottom: 0px;
	    }
	}
}
.logo{
	background: url('../img/logo_roig_curvas_negro.png');
	background-size: 90px 86px;
	width: 90px;
	height: 96px;
	margin-left: 55px;
	margin-top: 20px;
    background-repeat: no-repeat;	
    cursor: pointer;
    @include respondTo(tablet) {
		margin-left: 35px;
	}
	@include respondTo(smartphone) {
		background-size: 75%;
		margin-left: 20px;
	}
}
nav{
	margin-right: 80px;
	margin-top: 45px;
	height: auto;
	@include respondTo(tablet){
		margin-right: 30px;
	}
	ul.menu{
		@include respondTo(tablet){
			@include visuallyhidden();
		}
		li{
			float: left;
			margin-left: 65px;
			@include respondTo(desktop){
				margin-left: 45px;
			}
			&.activa{
				border-bottom: 2px solid $dorado-logo;
				padding-bottom: 7px;
			}
			a{
				@extend %menu;
				@include transition(0.2s);
				&:hover{
					@include transition(0.2s);
				}
			}
		}
	}
	ul.mobile{
		@include respondTo(notablet){
			@include visuallyhidden();
		}
		li{
			float: left;
			margin-left: 25px;
			&:first-child{
				margin-left: 0;
			}
			a{
				@extend %menu;
				i{
					color: $dorado-logo;
				}
			}
		}
	}
}