/* OVERLAY CONSULTA */

#consulta-overlay{

	.caja{
		margin-top: 100px;
		padding-bottom: 100px;
		background: $blanco;
		@include box-shadow(10px, 10px, 30px, 10px, rgba(0,0,0,0.5));
        .row{
            margin: auto 65px;
	        .cruz{
	            margin-top: 12px;
	            z-index: 99;
	            a{
	                @extend %p;
	                color: $dorado-logo;
	                font-size: 65px;
	                font-weight: 300;
	                @include transition(0.3s);
	                &:hover{
		                color: $azul-logo;
		                @include transition(0.3s);
	                }
	            }
	        }
	        h3{
	        	display: inline-block;
				font-size: 36px;
				letter-spacing: 5.25px;   
				@include respondTo(tablet){
					font-size: 24px;
				}
			}
	        span{
	            @extend %p;
				font-size: 24px;
				color: $gris-oscuro;
				letter-spacing: 3.5px;  
				padding-left: 10px;      
			}
			.foto,
			.formulario{
				margin-top: 80px;
			}
			.foto{
				border: 1px solid $gris-oscuro;
				padding: 0;
				@include respondTo(tablet){
					@include visuallyhidden();
				}
				.imagen{
					width: 100%;
					height: 460px;
					background-size: cover;
					background-position: center center;
				}
				.pie{
					.row{
						margin: 0;
						.tipo,
						.barrio,
						.ref{
			                @extend %p;
			                margin-right: 12px;
			                color: $gris-oscuro;
			                letter-spacing: 3.5;
						}
						.tipo{
							margin-left: 12px;
							font-size: 24px;
							padding-top: 20px;
						}
						.barrio{
							margin-left: 12px;
							font-size: 18px;
							padding-top: 10px;
							padding-bottom: 20px;
						}
						.ref{
			                margin-right: 12px;
			                float: right;
							font-size: 18px;
							margin-top: 40px;
						}
					}
				}
			}
			.form{
				margin-top: 50px;
				fieldset {
					position: relative;
					input, textarea{
						@extend %p;
						@include border-radius(0);
						-webkit-appearance: none;
						border: 0;
						border-bottom: 2px solid $gris-oscuro;
						color: $gris-1;
						font-size: 18px;
						margin-bottom: 42px;
						outline: none;
						padding: 8px;
						width: 100%;
						&:focus{
							color: $gris-oscuro;
							outline: none;
						}
						&:-webkit-autofill {
							-webkit-box-shadow: 0 0 0px 1000px white inset;
						}
						&:valid + label,
						&:focus + label{
							color: $gris-oscuro;
							transform: translate3d(0, -25px, 0);
							font-size: 10px;
							letter-spacing: 1px;
							.span{
								font-size: 10px;
							}
						}
						&[type="file"]{
							padding-bottom: 20px;
							float: right;
						}
					}
					textarea{
						resize: none;
						overflow: auto;
						height: 37px;
						position: absolute;
						&:valid,
						&:focus{
							padding-top: 25px;
						}
						&:valid + label.bordes,
						&:focus + label.bordes{
							color: $gris-oscuro;
							transform: translate3d(0, -25px, 0);
							font-size: 10px;
							letter-spacing: 1px;
							background: url('../img/bordes_380.png') no-repeat;
							display: block;
							transition: all 0.3s ease-in-out;
							padding: 15px 0;
							@include background-size(100%);
						}
					}
		  			label {
						@extend %p;
						position: absolute;
						top: 5px;
						left: 8px;
						color: #909090;
						transform: translate3d(0, 0, 0);
						transition: all 0.3s ease-in-out;
						cursor: text;
						span{
							color: red;
						}
					}
					.bordes{
						width: 100%;
						height: 10px;
						left: 0;
						padding-left: 8px !important;
						background: no-repeat;
						position: absolute;
						transition: all 0.3s ease-in-out;
					}
				}
				#boton-formulario{
					margin-top: 116px;
				}
			}
        }
	}
}