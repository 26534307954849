/* FICHA */

section.header_imprimible,
section.footer_imprimible{
	display: none;
}

section.ficha{
	margin-left: 55px;
	margin-right: 55px;
	margin-top: 80px;
	@include respondTo(tablet){
		margin-left: auto;
		margin-right: auto;	
		margin-left: 25px;
		margin-right: 25px;
	}
	@include respondTo(smartphone){
		margin-left: 10px;
		margin-right: 10px;
	}
	.container-fluid{
		.row{
			div{ // col-xs-12 col-md-6
				.row{
					&.titulo{
						div{ // col-xs-12
							h2{
								font-family: $font;
								font-size: 42px;
								color: $gris-oscuro;
								letter-spacing: 7px;
								text-transform: uppercase;
								@include respondTo(huge){
									font-size: 30px;
								}
								@include respondTo(tablet){
									text-align: center;
									width: 100%;
								}
								@include respondTo(smartphone){
									font-size: 24px;
								}
							}
							p.barrio{
								margin-top: 30px;
								font-family: $font;
								font-size: 18px;
								color: $gris-oscuro;
								letter-spacing: 2.62px;
								text-transform: uppercase;
								display: block;
								width: 100%;
								position: relative;
								@include respondTo(tablet){
									text-align: center;
								}
							}
							p.codigo{
								font-family: $font;
								font-size: 18px;
								color: $azul-logo;
								letter-spacing: 4.29px;
								font-weight: 300;
								text-align: right;
								@include respondTo(tablet){
									text-align: center;
									width: 100%;
									margin-top: 30px;
								}
								i{
									cursor: pointer;
									margin-top: 15px;
									@include transition(0.3s);
									&:hover{
										color: $dorado-logo;
										@include transition(0.3s);
									}
								}
							}
						}
					}
					&.alquiler-venta{
						margin-top: 50px;
						div{
							min-height: 85px;
							&:nth-child(2){
								border-left: 3px solid $azul-logo;
							}
							p{
								text-align: center;
								&.tipo{
									padding-top: 5px;
									font-family: $font;
									font-size: 24px;
									color: $gris-oscuro;
									letter-spacing: 3.5px;
									font-weight: 300;
								}
								&.valor{
									padding-top: 20px;
									font-family: $font;
									font-size: 24px;
									color: $gris-oscuro;
									letter-spacing: 5.72px;
									font-weight: 300;
									span{
										font-size: 0.8em;
										cursor: pointer;
										text-decoration: underline;
										letter-spacing: 2px;
									}
								}
							}
						}
					}
					$margin-top: 53px;
					&.cta{
						margin-top: $margin-top;
						button{
							border: 0;
							background: $azul-logo;
							color: $blanco;
							font-size: 18px;
							letter-spacing: 6.3px;
							font-weight: 300;
							height: 75px;
							&:hover{
								background: $blanco;
								color: $azul-logo;
								border: 2px solid $azul-logo;
							}
						}
					}
					&.datos{
						margin-top: ($margin-top + 450) * -1;
						@include respondTo(desktop){
							margin-top: 50px;
						}
						div.col-xs-12{
							padding: 0;
							.row{
								&::first-child{
									padding-bottom: 55px;
								}
								p.terreno{
									font-family: $font;
									font-size: 16px;
									color: $gris-oscuro;
									letter-spacing: 2px;
									line-height: 25px;
									font-weight: 300;
									text-align: left;
									margin-left: 15px;
									@include respondTo(desktop){
										margin-left: 0;
									}
								}
								div.col-xs-4{ // .col-xs-4
									padding-bottom: 55px;
									//padding-left: 0;
									//padding-right: 0;
									//min-height: 140px;
									@include respondTo(smartphone){
										width: 50%;
									}
									height: 140px;
									.icono{
										margin-bottom: 5px;
									}
									.cantidad{
										margin-bottom: 10px;
										font-family: $font;
										font-size: 24px;
										color: $gris-oscuro;
										letter-spacing: 5.72px;
										font-weight: 300;
										span{
											font-size: 14px;
										}
									}
									.texto{
										color: $gris-oscuro;
										//opacity: 0.8;
										font-family: $font;
										font-size: 14px;
										color: #505050;
										letter-spacing: 3.34px;
										font-weight: 300;
										sup{
											font-size: 10px;
										}
									}
								}
							}
						}
					}
					.barra{
						display: block;
						width: 85px;
						height: 3px;
						background: $azul-logo;
						position: relative;
						margin-top: 30px;
						@include respondTo(tablet){
							text-align: center;
							margin-right: auto;
							margin-left: auto;
						}
					}

				}
			}
			.col-gal{
				padding-left: 0;
				padding-right: 0;
				//margin-left: -15px;
				.galeria{
					//background: url('../img/slider_ficha.png');
					height: 500px;
					background-size: 100%;
					background-position: bottom;
					max-width: 600px;
					margin-left: auto;
					margin-right: auto;
					@include box-shadow(10px, 0px, 29px, 0px, rgba(0,0,0,0.5));
					@include respondTo(desktop){
						margin-top: 70px;
						max-width: 100%;
					}
				}
			}
		}
	}
}

section.caracterisiticas-descripcion{
	margin-left: 55px;
	margin-right: 55px;
	margin-top: 80px;
	@include respondTo(tablet){
		margin-left: auto;
		margin-right: auto;	
		margin-left: 25px;
		margin-right: 25px;
	}
	h3{
		font-family: $font;
		font-size: 24px;
		color: $gris-oscuro;
		letter-spacing: 3.5px;
		@include respondTo(desktop){
			text-align: center;
		}
	}
	p{
		margin-top: 45px;
		//padding-left: 10px;
		font-family: $font;
		font-size: 16px;
		color: $gris-oscuro;
		letter-spacing: 2px;
		line-height: 25px;
		font-weight: 300;
		text-align: left;
	}
	.caracterisiticas{
		margin-bottom: 100px;
		.row{
			p{
				display: inline-block;
				width: 50%;
				@include respondTo(desktop){
					text-align: center;
				}
				@include respondTo(smartphone){
					text-align: center;
					width: 100%;
				}
				.bullet{
					@include respondTo(nodesktop){
						display: none;
					}
				}
			}
			@include respondTo(smartphone){
				width: 100%;
			}
		}
	}
	.descripcion{
		p{
			@include respondTo(nodesktop){
				padding-right: 15px;
			}
		}
	}
}

section.ubicacion{
	margin-top: 80px;
	@include respondTo(tablet){
		margin-left: auto;
		margin-right: auto;	
	}
	div{
		padding-left: 0;
		padding-right: 0;
		h3{
			text-align: center;
		}
		p{
			margin-top: 30px;
			font-family: $font;
			font-size: 14px;
			color: $gris-oscuro;
			font-weight: 300;
			letter-spacing: 2.04px;
		}
		.mapa{
			margin-top: 40px;
			height: 350px;
			width: 100%;
			background: $azul-logo;
		}
	}
}

div.foto_principal_print{
	display: none;
}

section.cta{
	margin-top: 150px;
	@include respondTo(desktop){
		margin-top: 75px;
	}
}


section.similares{
	margin-left: 55px;
	margin-right: 55px;
	margin-top: 150px;
	@include respondTo(tablet){
		margin-left: 25px;
		margin-right: 25px;
		margin-top: 125px;
	}
	.row{
		div{
			@include respondTo(desktop){
				padding-left: 0;
				padding-right: 0;
			}
			.propiedad{
				border: 1px solid $gris-1;
				margin-top: 50px;
				margin-bottom: 50px;
				cursor: pointer;
				/*
				max-width: 300px;
				margin-left: auto;
				margin-right: auto;
				*/
				@include transition(all 0.3s);
				&:hover{
					border: 1px solid $gris-oscuro;
					@include transition(all 0.3s);
					cursor: pointer;
				}
				.img{
					height: 200px;
					background: url('../img/casa-skeleton.png') no-repeat center center $gris-3;
					img{
						object-fit: cover;
						height: 200px;
						width: 100%;
					}
				}
				.texto{
					//padding: 40px 0;
					height: 80px;
					p{
						color: $gris-oscuro;
						letter-spacing: 2.62px;
						padding-top: 15px;
						&.grande{
							font-size: 18px;
							@include respondTo(huge){
								font-size: 12px;
							}
							@include respondTo(desktop){
								font-size: 18px;
							}
						}
						&.chico{
							font-size: 12px;
							font-weight: 400;
						}
						&.left{
							padding-left: 22px;
						}
						&.right{
							padding-right: 22px;
						}
					}
				}
			}
		}
	}
}

