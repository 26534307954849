/* CONTACTO */

section.titulo{
	margin-top: 50px;
	margin-left: 50px;
	@include respondTo(desktop) {
		margin-left: 0;
	}
	h1{
		text-align: left; 
		@include respondTo(desktop) {
			text-align: center;
			font-size: 36px;
		}
		@include respondTo(tablet) {
			font-size: 18px;
		}
	}
}

section.contacto{
	margin-top: 50px;
	.imagen{
		background-image: url('../img/foto_contacto_3.jpg');
		height: 400px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding-left: 0;
		padding-right: 0;
		margin-left: 65px;
		@include respondTo(desktop) {
			height: 250px;
			margin-left: 0;
		}
		.pie{
			margin-top: 430px;
			@include respondTo(desktop) {
				margin-top: 270px;
				padding-left: 44px;
				padding-right: 44px;
				padding-bottom: 50px;
				border-bottom: 1px solid $gris-3;
			}
			.row{
				margin-top: 10px;
				@include respondTo(desktop) {
					margin-top: 0;
				}
				p{
					color: $gris-oscuro;
					span{
						font-weight: bold;
					}
				}
				.left,
				.right{
					@include respondTo(desktop) {
						@include push--auto;
						text-align: center;
						@include clearfix;
						display: block;
						width: 100%;
						padding-top: 20px;
					}
				}
			}
		}
	}
	.form{
		form{
			max-width: 400px;
			min-width: 230px;
			@include respondTo(desktop) {
				margin-top: 250px;
				@include push--auto;
			}
			button{
				position: absolute;
				margin-top: 116px;
				max-width: 400px;
				min-width: 230px;
				width: calc(100% - 30px);
			}
		}
	}
}
footer.contacto-footer{
	margin-top: 200px;
	margin-bottom: 50px;
	@include respondTo(desktop) {
		margin-top: 330px;
		padding-top: 50px;
		border-top: 1px solid $gris-3;
	}
}