/* PLACEHOLDERS */
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
// @extend %clearfix;

@mixin clearfix{
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

%visuallyhidden{
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}
// @extend %visuallyhidden;
@mixin visuallyhidden{
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

%check-box-mobile{
  label{
    //width: (100% / 6);
    @extend %p;
    -webkit-border-radius: 0px; 
    -moz-border-radius: 0px; 
    border-radius: 0px; 
    background: transparent;
    font-size: 14px;
    letter-spacing: 0.53px;
    color: $blanco;
    //width: calc( (100% / 6) + 1.7px);
    float: left;
    border: 2px solid $gris-1;
    display: block;
    margin: 0;
    margin-top: -2px;
    margin-left: -2px;
    left: 2px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    &.full-width{
      width: 100%;
      margin-top: 30px;
    }
    &.ui-state-active{
      background: $blanco;
      border: 2px solid $blanco;
      color: $dorado-logo;
      z-index: 6;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
    }
  }
}



