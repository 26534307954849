/* SLIDER */

.galeria{
	overflow: hidden;
	min-width: 300px;
	.contenedor-de-slides,
	.contenedor-de-slides-full{
		position: relative;
		height: 500px;
		@include transition(0.8s);
		.slide{
			height: 500px;
			float: left;
			background-size: cover;
			background-position: center;
		}
	}
	.contenedor-de-slides-full{
		@include transition(0.8s);
		height: 100%;
		.slide{
			height: 100%;
		}
	}
	.flechas{
		width: 100%;
		z-index: 500;
		.izq,
		.der{
			position: relative;
			width: 34px;
			height: 65px;
			background: url('../img/felcha_slider.png') no-repeat;
			background-size: 34px 65px;
			cursor: pointer;
		}
		.izq{
			float: left;
			margin-left: 45px;
			@include respondTo(tablet){
				margin-left: 20px;
			}
		}
		.der{
			float: right;
			margin-right: 45px;
			@include rotar(180);
			@include respondTo(tablet){
				margin-right: 20px;
			}
		}
	}
	.over{
		position: absolute;
		width: 100%;
		max-width: 600px;
		margin-top: -100px;
		.wrapper{
			width: 300px;
			position: relative;
			float: none;
			@include respondTo(smartphone){
				width: 200px;
			}
			@include respondTo(tablet){
				width: 250px;
			}
			.izq{
				background: url('../img/felcha_slider.png') no-repeat;
				background-size: 20px 40px;
				height: 40px;
				width: 20px;
				float: left;
				margin-top: 5px;
				cursor: pointer;
			}
			button.cta{
				background: transparent;
				width: 140px;
				float: left;
				margin-left: 60px;
				@include respondTo(smartphone){
					margin-left: 10px;
				}				
				@include respondTo(tablet){
					margin-left: 35px;
				}
				font-size: 14px;
				color: $blanco;
				letter-spacing: 1.5px;
				border: 3px solid $blanco;
				&:hover{
					border: 3px solid $blanco;
					background: $blanco;
					color: $gris-oscuro;
				}
			}
			.der{
				background: url('../img/felcha_slider.png') no-repeat;
				background-size: 20px 40px;
				height: 40px;
				width: 20px;
				float: left;
				margin-top: 5px;
				cursor: pointer;
				@include rotar(180);
			}
		}
	}
}
.botones{
	position: relative;
	height: 10px;
	z-index: 9;
	display: block;
	margin-top: 50px;
	ul.botonera{
		display: block;
		li{
			width: 12px;
			height: 12px;
			display: block;
			position: relative;
			margin-top: 0;
			float: left;
			border: 2px solid $gris-2;
			margin: 0 7px;
			cursor: pointer;
			@include border-radius(6px);
			@include transition(0.3s);
			&.active{
				border: 2px solid $gris-oscuro;
			}
		}
	}
}
#full-slider{
	@include transition(opacity 0.8s);
	overflow: hidden;
	.cruz{
		@include respondTo(tablet){
			position: absolute;
			a{
				color: $blanco;
			}
		}
	}
	.galeria{
		.contenedor-de-slides-full{
			@include transition(0.8s);
		}
	}
	.botones{
		@include respondTo(tablet){
			position: absolute;
			margin-top: -50px;
		}
		ul.botonera{
			margin-bottom: 35px;
			li{
				@include respondTo(tablet){
					border: 2px solid $blanco;
					&.active{
						border: 2px solid $gris-oscuro;
					}
				}
			}
		}
	}
}


